import { APIRoutes } from 'constants/';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// Define an interface for the wall data response
export interface WallDataResponse {
  // Add specific properties expected in the response
  // For example:
  // surveys: Survey[];
  // rewards: Reward[];
  // Add more properties as needed
  [key: string]: any; // This allows for additional properties
}

// Define an interface for the query parameters
export interface WallDataQueryParams {
  [key: string]: string | number | boolean | undefined | null;
}

export const wallApi = createApi({
  reducerPath: 'wallApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PUBLIC_API_HOST,
  }),
  endpoints: (build) => ({
    getWallData: build.query<WallDataResponse, WallDataQueryParams>({
      query: (params) => ({
        url: APIRoutes.Wall,
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetWallDataQuery } = wallApi;
